import store from '../store'

export const fetchData = {
    methods: {
        // 해당 url로 데이터 요청 후 응답오면 콜백 실행
        fetchData(url, callback=()=>{}, params={}){
            store.commit('startLoading');   // 로딩 시작
            this.axios.get(url, { params : params })
            .then(res=>{
                // console.log(res.data);
                store.commit('finishLoading');  // 로딩 완료
                if(res.data.success){
                    callback(res.data);
                } else {
                    store.commit('error', '데이터를 받아오는것에 실패했습니다.');
                }
            }).catch(error=>{
                store.commit('error', '데이터를 받아오는 중 문제가 생겼습니다.');
                store.commit('finishLoading');  // 로딩 완료
                console.error(error);
            });
        }
    },
}