<template>
    <!-- 푸터 START -->
	<footer class="footer_container c_section">
		<div class="footer_top">
			<div class="c_inner">
				<nav class="terms">
					<router-link to="/terms/privacyPolicy">개인정보 취급방침</router-link>
					<router-link to="/terms/privacyProtection">개인정보 보호규정</router-link>
					<router-link to="/terms/emailDenial">이메일 무단수집거부</router-link>
					<router-link to="/terms/serviceTerms">이용약관</router-link>
				</nav>
			</div>
		</div>
		<div class="footer_info">
			<div class="c_inner">
				<div class="footer_info01">
					<p>주식회사 티온컴퍼니</p>
					<p>대표자: 김소라</p>
					<p>메일: ton@toncompany.co.kr</p>
					<p>전화: 02-567-7705</p>
				</div>
				<div class="footer_info02">
					<p>사업자 등록번호: 342-87-02602</p>
					<p>통신판매업신고: 제 2023-서울강남-03151호</p>
					<address>주소: (06174) 서울특별시 강남구 영동대로82길 9, G1(대치동)</address>
					<p>ⓒ 2021 T-ONPAY COMPANY. COPYRIGHT ALL RIGHTS RESERVED.</p>
				</div>
			</div>
		</div>
	</footer>
    <!-- 푸터 END -->
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped src="@/assets/css/components/footer.css">
</style>