<template>
    <div class="c_screen_filter" v-if="isToggle" @click="isToggle=false"></div>
    <!-- depth가 1인 헤더 -->
    <header class="header_container c_section" id="header_container">
        <div class="c_inner">
            <nav class="header_left">
                <router-link class="ci" to="/" @click="toggleHam('remove')">
                    <img src="@/assets/image/common/logo_white.svg" alt="logo" />
                </router-link>
            </nav>
            <button class="btn_ham" id="btn_ham" @click="toggleHam('toggle')">
                <div class="ham_line"></div>
                <div class="ham_line"></div>
                <div class="ham_line"></div>
            </button>
            <nav class="header_right">
                <ul class="menu">
                    <li><router-link to="/Company">회사소개</router-link></li>
                    <li><router-link to="/pay/shoppingmall">결제서비스</router-link></li>
                    <li><router-link to="/service/guide">서비스 신청하기</router-link></li>
                    <li><router-link to="/customer/noticeList">고객센터</router-link></li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
export default {
    name: 'Header',
    watch: {
        // 라우트 이동시 헤더 닫기(모바일), 홈에서 사용한 스크롤시 배경색 없애는 기능 토글
        $route(to) {
            const header_container = document.getElementById('header_container');
            this.toggleHam('remove');
            if(to.path == '/'){
                window.addEventListener('scroll', this.eventScroll);
                header_container.classList.add('main');
            } else {
                window.removeEventListener('scroll', this.eventScroll);
                header_container.classList.remove('main');
            }
        },
        // 헤더 열리고 닫힐 때
        isToggle(val){
            const btn_ham = document.getElementById('btn_ham');
            const header_container = document.getElementById('header_container');
            if(val){
                btn_ham.classList.toggle('on');
                header_container.classList.toggle('active');
                header_container.classList.remove('main');
            } else {
                btn_ham.classList.remove('on');
                header_container.classList.remove('active');
                if(this.$route.path == "/" && window.scrollY <= 20){
                    header_container.classList.add('main');
                }
            }
        }
    },
    data(){
        return {
            isToggle : false,
            isScrolled : false,
        }
    },
    mounted(){
        window.addEventListener('resize', this.checkHeaderSize);
    },
    unmounted(){
        window.removeEventListener('resize', this.checkHeaderSize)
    },
    methods:{
        // 메뉴 펼치고 접기(모바일)
        toggleHam(action='toggle'){
            if(action == 'toggle'){
                this.isToggle = !this.isToggle;
            } else if(action == 'remove'){
                this.isToggle = false;
            }
        },
        checkHeaderSize(event){
            if(event.target.innerWidth > 768){
                this.isToggle = false;
            }
        },
        eventScroll(){
            const header_container = document.getElementById('header_container');
            if(window.scrollY > 20){
                header_container.classList.remove('main');
            } else {
                if(!header_container.classList.contains('active')){
                    header_container.classList.add('main');
                }
            }
        }
    },
}
</script>

<style scoped src="@/assets/css/components/header.css">
</style>
