<template>
    <!-- <div id="toTop" class="toTop" @click="toTop">
        <p>TOP</p>
    </div> -->
    <div id="toTop" class="toTop" @click="toTop">
        <p>TOP</p>
    </div>
</template>

<script>
export default {
    name : "ToTop",
    mounted(){
        window.addEventListener('scroll', this.toTopScrolled);
    },
    unmounted(){
        window.removeEventListener('scroll', this.toTopScrolled);
    },
    methods:{
        toTopScrolled(){
            const toTopEl = document.getElementById('toTop');
            if(window.scrollY > 50){
                toTopEl.classList.add('scrolled');
            } else{
                toTopEl.classList.remove('scrolled');
            }
        },
        toTop(){
            window.scrollTo({top : 0, behavior : 'smooth'});
        },
    }
}
</script>

<style scoped>
/* toTop 시작 */
.toTop{
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-mid);
    font-weight: var(--font-w-bold);
    letter-spacing: 0;
    color: var(--primary-color-contrast);
    background-color: var(--secondary-color);
    border-radius: var(--border-radius-large);
    box-shadow: var(--box-shadow);
    opacity: 0;
    transition: .6s;
    /*mix-blend-mode: difference;*/     /* 색 반전효과. body에 배경색 #fff를 넣지 않으면 안보일 수도 있음 */
    z-index: 10;
    visibility: hidden;
}
.toTop .arrowUp{
    width: 80%;
    height: 80%;
}

/* 스크롤시 START */
.toTop.scrolled{
    cursor: pointer;
    opacity: 1;
    visibility: visible;
}
</style>